import React, { ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import BreadCrumb from '../../components/bread-crumb/bread-crumb';
import ContainerBox from '../../components/container-box/container-box';
import WordPressPageLayout from '../../components/wordpress-page-layout/wordpress-page-layout';

export default class Methodology extends BaseComponent {
  protected declareTranslateCollection(): string {
    return 'methodology';
  }

  public render(): ReactNode {
    return (
      <WordPressPageLayout title={this.translate('pageTitle')}>
        <ContainerBox className="c-section--pageheader">
          <div className="c-pageheader">
            <BreadCrumb
              crumbItems={[
                {
                  label: 'State of Democracy',
                  location: '/',
                },
                {
                  label: 'Read the report',
                  location: 'read-the-report',
                },
              ]}
            />
            <div className="c-section">
              <h1 className="c-section__title">
                {this.translate('pageTitle')}
              </h1>
            </div>
          </div>
        </ContainerBox>
        <div>Read the Report</div>
        <br />
      </WordPressPageLayout>
    );
  }
}
